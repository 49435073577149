const state = {
  userName: '',
  userLogged: false,
  userLoaded: false,

  versions: {
    zw: '',
    fe: '',
    alyson: '',
    wolke: '',
  },

  infoLinks: [
    { title: 'Packages', icon: 'mdi-package-variant', to: 'packages' },
    { title: 'Notifications', icon: 'mdi-bell', to: 'notifications' },
    {
      title: 'auth doc',
      icon: 'mdi-api',
      url: 'https://lothub.lotint.net/user/doc',
      class: 'text-caption text-uppercase',
      target: '_blank',
    },
    {
      title: 'fe doc',
      icon: 'mdi-api',
      url: 'https://lothub.lotint.net/api/fe/v2/doc',
      class: 'text-caption text-uppercase',
      target: '_blank',
      version: 'fe',
    },
    {
      title: 'zw doc',
      icon: 'mdi-api',
      url: 'https://zw.lotint.net/api/docs',
      class: 'text-caption text-uppercase',
      target: '_blank',
      version: 'zw',
    },
    {
      title: 'alyson doc',
      icon: 'mdi-api',
      url: 'http://alyson-api.v.lotserver.de:2323/doc',
      class: 'text-caption text-uppercase',
      target: '_blank',
      version: 'alyson',
    },
    {
      title: 'wolke doc',
      icon: 'mdi-api',
      url: 'https://lothub.lotint.net/api/wolke/v1/doc',
      class: 'text-caption text-uppercase',
      target: '_blank',
      version: 'wolke',
    },
  ],

  tagMakerIsLoading: false,

  useGlobalDate: false,
  globalDateFrom: undefined,
  globalDateTo: undefined,
  globalUsers: 0,
};

export default state;
