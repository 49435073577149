<template>
  <v-app>
    <navigation-drawer />
    <app-bar />

    <v-main v-show="userLoaded">
      <v-container v-if="!userLogged" fill-height fluid>
        <v-row align="center" justify="center">
          <v-col>
            <h1 class="text-center">Oops...! Please login!</h1>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else v-scroll="onScroll" fluid style="margin-bottom: 140px">
        <keep-alive>
          <router-view />
        </keep-alive>
        <v-fab-transition>
          <v-btn
            v-show="fab"
            fab
            fixed
            right
            :light="$vuetify.theme.dark"
            :dark="!$vuetify.theme.dark"
            style="bottom: 85px"
            @click="toTop"
          >
            <v-icon>keyboard_arrow_up</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-container>
    </v-main>

    <v-snackbar v-model="alert" :color="isError ? 'error' : 'success'" multi-line top>
      <div class="text-center">
        <span v-if="isError" class="text-left pr-4">
          <p class="title">Oops! Server return: {{ status }}</p>
          <p>
            There is in body:<br /><code>{{ body }}</code>
          </p>
        </span>
        <span v-else class="mr-2">{{ msg }}</span>
        <v-btn dark text class="mx-auto" @click="alert = false"> Close </v-btn>
      </div>
    </v-snackbar>
    <app-confirm-popup ref="confirm" />
  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import NavigationDrawer from '@/components/NavigationDrawer.vue';
import AppBar from '@/components/AppBar.vue';
import AppConfirmPopup from '@/components/ConfirmPopup.vue';

export default {
  components: {
    AppBar,
    NavigationDrawer,
    AppConfirmPopup,
  },
  data: () => ({
    fab: false,
  }),
  computed: {
    ...mapState('user', ['userLogged', 'userLoaded']),
    ...mapState('alert', ['show', 'isError', 'msg', 'status', 'body']),
    ...mapGetters(['localSettings']),

    alert: {
      get() {
        return this.show;
      },
      set(v) {
        if (!v) {
          this.$store.dispatch('alert/hideAlert');
        }
      },
    },
  },
  mounted() {
    this.getLocalSettings();
    this.getLocalLogs();
    this.getUser();
    this.getVersions();
    this.$root.$confirm = this.$refs.confirm;
    this.$vuetify.theme.dark = this.localSettings.isDark || false;
  },
  methods: {
    ...mapActions('user', ['getUser', 'getVersions']),
    ...mapActions('alert', ['getLocalLogs', 'hideAlert']),
    ...mapActions(['getLocalSettings']),

    onScroll(e) {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 140;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style>
.gchart-skeleton .v-skeleton-loader__image {
  height: 448px;
}
.clickable {
  cursor: pointer;
}
.base__link {
  text-decoration: none;
  color: inherit !important;
}
.d-contents {
  display: contents;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #e0e0e0 !important;
}
.v-badge__badge .v-icon {
  font-size: inherit !important;
}

.jsonb {
  cursor: pointer;
  max-width: 250px;
}
.jsonb.autowidth {
  max-width: initial;
}
.jsonb code {
  background-color: #e0e0e0;
  font-size: 75%;
  font-weight: 400;
}
</style>
