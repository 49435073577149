import axios from '@/plugins/axios';
import { processAxiosException } from '@/plugins/mixin';

const actions = {
  async getUser({ commit, dispatch }) {
    commit('changeState', { userLoaded: false });
    await axios
      .get('/user', {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '-1',
        },
      })
      .then(({ data }) => {
        commit('changeState', { userName: data.name, userLogged: true });
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { userLoaded: true });
  },

  getVersions({ commit, dispatch, rootGetters }) {
    const versions = [
      { key: 'fe', getter: 'feApi' },
      { key: 'zw', getter: 'zwApi' },
      { key: 'alyson', getter: 'alysonApi' },
      { key: 'wolke', getter: 'wolkeAPI' },
    ];
    versions.forEach(async ({ key, getter }) => {
      let version = 'XXXX.XX.XX.X';
      await axios
        .get(`${rootGetters[getter]}/version`)
        .then(({ data }) => {
          version = data;
        })
        .catch((error) => {
          dispatch('alert/setError', processAxiosException(error), {
            root: true,
          });
        });
      commit('updateCompositeState', {
        itemKey: 'versions',
        changed: { [key]: version },
      });
    });
  },

  async makeBuild({ commit, dispatch }, { repo }) {
    commit('changeState', { tagMakerIsLoading: true });
    let isSuccess = false;
    await axios
      .post(`https://ci.lotint.net/api/repos/lotint/${repo}/cron/autocreatetag`, null, {
        withCredentials: false,
        headers: {
          authorization: `Bearer ${process.env.VUE_APP_DRONE_TOKEN}`,
        },
        transformRequest: (data, headers) => {
          // eslint-disable-next-line no-param-reassign
          delete headers.AuthToken;
          return JSON.stringify(data);
        },
      })
      .then(() => {
        isSuccess = true;
      })
      .catch((error) => {
        dispatch('alert/setError', processAxiosException(error), {
          root: true,
        });
      });
    commit('changeState', { tagMakerIsLoading: false });
    return isSuccess;
  },
};

export default actions;
